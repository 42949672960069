<template>
  <div class="w-full max-w-md">
    <form v-show="!otp.show" @submit.prevent="submitLogin">
      <div class="mb-6 md:mb-12">
        <h1
          class="mb-4 text-left text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
        >
          {{ title }}
        </h1>
        <Alert v-if="signedUp" type="success">
          <p class="mb-2">Fantastic! You're just about ready to log in!</p>
          <p>
            Check your email for instructions on confirming your email address.
          </p>
        </Alert>
        <Alert v-else-if="loggedOut" type="success">
          You've successfully logged out.
        </Alert>
        <Alert v-else-if="confirmedEmail" type="success">
          Fantastic! You may now log in to your account.
        </Alert>
        <Alert v-else-if="joinedAccount" type="success">
          Fantastic! You may now log in & get started!
        </Alert>
        <template v-if="errors.length > 0">
          <Alert
            :type="
              errors.some((error) =>
                error.includes('updated security procedure'),
              )
                ? 'warning'
                : 'error'
            "
          >
            <p v-for="(error, index) in errors" :key="index">
              {{ error }}
            </p>
          </Alert>
        </template>
      </div>
      <label for="email" class="mb-1 inline-block"> Email </label>
      <input
        id="email"
        ref="email"
        v-form-control
        v-model="email"
        type="email"
        name="email"
        autocomplete="username"
        class="mb-5 text-lg md:text-base"
        :required="true"
      />

      <label for="email" class="mb-1 inline-block"> Password </label>
      <input
        id="password"
        ref="password"
        v-form-control
        v-model="password"
        type="password"
        name="password"
        autocomplete="current-password"
        class="text-lg md:text-base"
        :required="true"
      />
      <div class="mt-10 flex items-center justify-between md:mt-14">
        <button
          type="submit"
          class="btn btn-purple"
          :class="{ loading: submitting }"
          :disabled="submitting"
        >
          Log in
        </button>
        <RouterLink
          to="/password/reset"
          class="text-sm text-gray-600 hover:text-purple-500 dark:text-gray-500 dark:hover:text-purple-500"
        >
          Forgot Password?
        </RouterLink>
      </div>
    </form>
    <VerifyLoginOTP
      v-if="otp.show"
      :channel="otp.channel"
      :device="otp.device"
    />
  </div>
</template>

<script>
import Alert from '@web/components/utilities/Alert.vue';
import locationReloadOnce from '@web/utils/locationReloadOnce.js';
import VerifyLoginOTP from '@web/components/page/login/VerifyLoginOTP.vue';

export default {
  components: {
    VerifyLoginOTP,
    Alert,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loggedOut = !!vm.$route.query.logout;
      vm.signedUp = !!vm.$route.query.signup;
      vm.confirmedEmail = !!vm.$route.query.confirmed;
      vm.joinedAccount = !!vm.$route.query.joined;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.reset();
    this.email = '';
    this.password = '';
    next();
  },
  data() {
    return {
      errors: [],
      submitting: false,
      signedUp: false,
      loggedOut: false,
      confirmedEmail: false,
      joinedAccount: false,
      otp: {
        show: false,
        channel: null,
        device: null,
      },
      email: '',
      password: '',
    };
  },
  computed: {
    title() {
      return this.signedUp ? "Let's get started" : 'Welcome back';
    },
  },
  created() {
    this.errors = window.Clearstream.errors;
    this.email = window.Clearstream.email;
  },
  methods: {
    reset() {
      this.loggedOut = false;
      this.signedUp = false;
      this.confirmedEmail = false;
      this.joinedAccount = false;
      this.errors = [];
    },
    async submitLogin() {
      this.submitting = true;

      // Refresh CSRF token. We do this proactively just in
      // case the user has been on this page for awhile and
      // the CSRF token has become invalid.
      await axios.get('/ajax/csrf');

      this.reset();
      // This is just a fallback mechanism, to get around some edge-cases
      // where password-autofill doesn't update the v-model data.
      // https://github.com/vuejs/vue/issues/5248#issuecomment-447870218
      this.email = this.$refs.email.value;
      this.password = this.$refs.password.value;
      axios
        .post('/ajax/login', {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => {
          if (data.status === 'VERIFICATION_REQUIRED') {
            this.submitting = false;
            this.otp = { ...data, show: true };

            return;
          }
          window.location.replace(data.intended ?? '/');
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            locationReloadOnce();
          }

          this.submitting = false;

          this.errors.push(err.getErrorMessage());

          if (err.hasErrorMessage()) {
            this.password = '';
            return;
          }

          throw err;
        });
    },
  },
};
</script>
