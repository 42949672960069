<template>
  <div class="w-full max-w-md">
    <div class="relative mb-6 md:mb-12">
      <h1
        class="mb-2 text-left text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
      >
        Forgot your password?
      </h1>
      <p class="text-left text-gray-700 dark:text-gray-500">
        Just enter your email address and we'll send you a link to reset it.
      </p>
      <Alert v-if="error" type="error" class="mt-4">
        {{ errorMsg }}
      </Alert>
    </div>
    <div v-if="passwordWasReset">
      <Alert
        v-if="passwordWasReset && !error && !submitting"
        type="success"
        class="mb-12"
      >
        Check your email for instructions on resetting your password.
      </Alert>
      <div class="mb-12 flex justify-end">
        <RouterLink
          to="/login"
          class="text-sm text-gray-600 hover:text-purple-500 dark:text-gray-500 dark:hover:text-purple-500"
        >
          ← Back to Log in
        </RouterLink>
      </div>
      <div
        class="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-4 text-sm text-gray-600 dark:bg-gray-800 dark:text-gray-300"
      >
        <div>
          Having trouble? Check your spam folder, or
          <span
            class="cursor-pointer text-purple-500 underline dark:text-purple-400"
            @click="resendPasswordResetEmail"
            >resend the email</span
          >.
        </div>
        <Spinner
          v-if="submitting"
          class="text-purple-500 dark:text-purple-400"
          :size="14"
        />
      </div>
    </div>
    <form v-if="!passwordWasReset" @submit.prevent="submitForm">
      <label for="email" class="mb-1 inline-block"> Email </label>
      <input
        id="email"
        v-form-control
        v-model="email"
        type="email"
        name="email"
        autocomplete="username"
        class="text-lg md:text-base"
      />
      <div class="mt-10 flex items-center justify-between md:mt-14">
        <button
          type="submit"
          class="btn btn-purple"
          :class="{ loading: submitting }"
          :disabled="submitting"
        >
          Submit
        </button>
        <RouterLink
          to="/login"
          class="text-sm text-gray-600 hover:text-purple-500 dark:text-gray-500 dark:hover:text-purple-500"
        >
          ← Back to Log in
        </RouterLink>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from '@web/components/utilities/Alert.vue';
import Spinner from '@web/components/utilities/Spinner.vue';

export default {
  components: { Spinner, Alert },
  beforeRouteLeave(to, from, next) {
    this.reset();
    this.email = '';
    next();
  },
  data() {
    return {
      submitting: false,
      error: false,
      errorMsg: '',
      email: '',
      passwordWasReset: false,
    };
  },
  methods: {
    reset() {
      this.error = false;
      this.errorMsg = '';
      this.passwordWasReset = false;
    },
    submitForm(reset = true) {
      this.submitting = true;
      if (reset) {
        this.reset();
      }

      axios
        .post('/ajax/password/reset/email', {
          email: this.email,
        })
        .then((response) => {
          this.submitting = false;
          this.passwordWasReset = true;
        })
        .catch((err) => {
          this.error = true;
          this.submitting = false;

          this.errorMsg = err.getErrorMessage();

          if (!err.hasErrorMessage()) {
            throw err;
          }
        });
    },
    resendPasswordResetEmail() {
      this.submitForm(false);
    },
  },
};
</script>
