import { bootVueApp } from '@web/bootstrap.js';
import Login from '@web/components/page/login/Login.vue';
import LoginForm from '@web/components/page/login/LoginForm.vue';
import PasswordResetRequestForm from '@web/components/page/login/PasswordResetRequestForm.vue';
import PasswordResetForm from '@web/components/page/login/PasswordResetForm.vue';
import ConfirmSignupForm from '@web/components/page/login/ConfirmSignupForm.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createApp } from 'vue';
import preferOpeningHelpdeskArticlesInIntercomWidget from '@web/utils/preferOpeningHelpdeskArticlesInIntercomWidget.js';

preferOpeningHelpdeskArticlesInIntercomWidget();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/login', component: LoginForm },
    { path: '/password/reset', component: PasswordResetRequestForm },
    { path: '/password/reset/:token', component: PasswordResetForm },
    {
      path: '/confirm/:token',
      component: ConfirmSignupForm,
      name: 'confirmSignup',
    },
  ],
});

const app = createApp(Login);

bootVueApp(app);

app.use(router);

app.mount('#app');
