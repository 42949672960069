<template>
  <h1
    class="mb-1 text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
  >
    Let's verify it's you 👋
  </h1>
  <form class="mb-6">
    <div class="mb-6">
      <p class="text-gray-700 dark:text-gray-500">
        Enter the six-digit code we just sent to {{ device }}.
      </p>
    </div>

    <VerificationCodeInput
      ref="verification-code-input"
      :disabled="submitting"
      @code-entered="submitCode"
    />

    <div v-if="submitting" class="mt-4 flex">
      <Spinner class="mr-2 text-purple-500" />
      <p>Checking your code...</p>
    </div>

    <Alert v-if="error" type="error" class="mt-4">
      <p>
        {{ error }}
      </p>
    </Alert>
  </form>
  <div
    class="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-4 text-sm text-gray-600 dark:bg-gray-800 dark:text-gray-300"
  >
    <div>
      Having trouble?
      <template v-if="channel === 'SMS'">
        <a
          class="text-purple-500 underline dark:text-purple-400"
          :class="disableResend ? 'cursor-not-allowed' : 'cursor-pointer'"
          @click.prevent="resendOTP"
          >Resend the text</a
        >.
      </template>
      <template v-if="channel === 'EMAIL'">
        Check your spam folder, or
        <a
          class="text-purple-500 underline dark:text-purple-400"
          :class="disableResend ? 'cursor-not-allowed' : 'cursor-pointer'"
          @click.prevent="resendOTP"
          >resend the email</a
        >.
      </template>
    </div>
    <Spinner
      v-if="sendingOTP"
      class="text-purple-500 dark:text-purple-400"
      :size="14"
    />
  </div>
</template>

<script>
import VerificationCodeInput from '@web/components/page/login/OTPInput.vue';
import Alert from '@web/components/utilities/Alert.vue';
import Spinner from '@web/components/utilities/Spinner.vue';

export default {
  components: { Spinner, Alert, VerificationCodeInput },
  props: {
    device: {
      type: String,
      required: true,
    },
    channel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sendingOTP: false,
      disableResend: false,
      submitting: false,
      error: null,
    };
  },
  methods: {
    submitCode(code) {
      this.submitting = true;
      this.error = null;

      axios
        .post('/ajax/login/verify', {
          code: code,
        })
        .then(({ data }) => {
          window.location.replace(data.intended ?? '/');
        })
        .catch((err) => {
          this.submitting = false;

          this.error = err.getErrorMessage();

          if (err.hasErrorMessage()) {
            this.$refs['verification-code-input'].reset();
            return;
          }

          throw err;
        });
    },
    resendOTP() {
      if (this.disableResend) {
        return false;
      }

      this.sendingOTP = true;
      this.disableResend = true;
      this.error = null;

      axios
        .post('/ajax/login/send-otp')
        .catch((err) => {
          this.error = err.getErrorMessage();

          throw err;
        })
        .finally(() => {
          this.sendingOTP = false;
          setTimeout(() => (this.disableResend = false), 5_000);
        });
    },
  },
};
</script>
