<template>
  <div class="grid w-full grid-cols-10 sm:min-h-screen">
    <div
      class="flex flex-col items-start bg-white px-6 pb-18 pt-6 dark:bg-gray-900 sm:px-12 sm:pt-12 lg:px-24"
      :class="{
        'col-span-10 md:col-span-6 lg:col-span-5': showRightSide,
        'col-span-10': !showRightSide,
      }"
    >
      <div class="flex w-full flex-row items-center justify-between">
        <a href="/"><LogoFull class="mt-2 h-[27px] sm:mt-0" /></a>
        <div
          v-if="showSignupOption"
          class="text-sm text-gray-600 md:hidden md:text-base"
        >
          <span class="hidden sm:inline">Need an account?&nbsp;</span>
          <a href="/signup" class="text-purple-500"
            >Sign up<span class="sm:hidden"> &rarr;</span></a
          >
        </div>
      </div>
      <div class="my-12 flex w-full flex-1 items-center justify-center sm:my-8">
        <RouterView v-slot="{ Component }">
          <KeepAlive>
            <Component :is="Component" />
          </KeepAlive>
        </RouterView>
      </div>
    </div>
    <div
      v-if="showRightSide"
      class="hidden flex-col border-l border-gray-200 bg-gray-100 px-6 pb-18 pt-6 dark:border-gray-700 dark:bg-gray-800 sm:px-12 sm:pt-12 md:col-span-4 md:block lg:col-span-5 lg:px-24"
    >
      <div v-if="showSignupOption" class="flex w-full flex-row justify-end">
        <div class="text-sm text-gray-600 dark:text-gray-500 md:text-base">
          Need an account?
          <a href="/signup" class="text-purple-500">Sign up</a>
        </div>
      </div>

      <Transition name="fade">
        <div
          v-if="latestBlogpost && latestChangelogPost"
          class="flex h-full flex-col justify-center"
        >
          <div v-if="latestBlogpost" class="max-w-lg rounded-lg px-6 py-6">
            <a
              :href="latestBlogpost.url"
              target="_blank"
              class="block max-w-full transition hover:opacity-80"
              style="width: 260px"
            >
              <img
                v-if="latestBlogpost.image"
                :src="latestBlogpost.image"
                class="w-full rounded-lg"
              />
            </a>
            <h2 class="mt-5 text-xl font-semibold leading-snug">
              <a
                :href="latestBlogpost.url"
                target="_blank"
                class="hover:underline"
              >
                {{ latestBlogpost.title }}
              </a>
            </h2>
            <div
              class="relative mt-2 line-clamp-4 leading-6 text-gray-600 dark:text-gray-500"
            >
              {{ truncateBlogpostDescription(latestBlogpost.description) }}

              <a
                :href="latestBlogpost.url"
                target="_blank"
                class="inline-flex items-center text-gray-600 underline dark:text-gray-500"
              >
                <span class="pr-1">Read more</span>
                <ExternalLinkArrow />
              </a>
            </div>
          </div>

          <div v-if="latestChangelogPost" class="mt-3 max-w-lg px-6 py-6">
            <p
              class="mb-3 block text-sm font-semibold uppercase tracking-wider text-purple-500"
            >
              CHANGELOG
            </p>
            <div class="flex flex-wrap items-end">
              <h2
                class="mr-2 text-xl font-semibold leading-snug hover:underline"
              >
                <a
                  href="https://clearstream.io/changelog"
                  target="_blank"
                  class="hover:underline"
                >
                  {{ latestChangelogPost.title }}
                </a>
              </h2>
            </div>

            <div
              class="changelog-post-content mt-3 line-clamp-4"
              @click="onChangelogPostContentClick"
              v-html="latestChangelogPost.content"
            />
            <a
              href="https://clearstream.io/changelog"
              target="_blank"
              class="mt-4 inline-flex items-center text-gray-600 underline dark:text-gray-500"
            >
              <span class="pr-1">See all updates</span>
              <ExternalLinkArrow />
            </a>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import LogoFull from '@web/components/utilities/svg/LogoFull.vue';
import ExternalLinkArrow from '@web/components/utilities/svg/ExternalLinkArrow.vue';

export default {
  components: {
    ExternalLinkArrow,
    LogoFull,
  },
  data() {
    return {
      latestBlogpost: null,
      latestChangelogPost: null,
    };
  },
  computed: {
    showRightSide() {
      if (this.$route.name === 'confirmSignup') {
        return false;
      }

      return true;
    },
    showSignupOption() {
      if (this.$route.name === 'confirmSignup') {
        return false;
      }

      return true;
    },
  },
  created() {
    this.getLatestBlogpost();
    this.getLatestChangelogPost();
  },
  methods: {
    onChangelogPostContentClick(event) {
      if (event.target.tagName === 'A') {
        event.preventDefault();
        window.open(event.target.href, '_blank');
      }
    },
    truncateBlogpostDescription(blogpostDescription) {
      return _.truncate(blogpostDescription, {
        length: 140,
        separator: /,? +/,
      });
    },
    getLatestBlogpost() {
      axios.get('/ajax/latest-blogpost').then((res) => {
        this.latestBlogpost = res.data.data;
      });
    },
    getLatestChangelogPost() {
      axios.get('/ajax/latest-changelog-post').then((res) => {
        this.latestChangelogPost = res.data.data;
      });
    },
  },
};
</script>

<style>
.changelog-post-content {
  @apply leading-6 text-gray-600 dark:text-gray-500;

  b,
  strong {
    @apply font-semibold;
  }

  em,
  i {
    @apply italic;
  }

  a {
    @apply underline;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    margin: 0;
    padding: 0 0 0 1rem;
  }
}
</style>
