<template>
  <div class="space-y-0.5">
    <div
      v-for="(requirement, requirementIndex) in requirements"
      :key="requirementIndex"
      class="flex items-center"
    >
      <div class="mr-2 flex h-3 w-3 items-center justify-center">
        <CheckBold v-if="requirement.value" class="text-purple-500" />
        <div v-else>•</div>
      </div>
      <div :class="{ 'font-semibold': requirement.value }">
        {{ requirement.label }}
      </div>
    </div>
  </div>
</template>

<script>
import CheckBold from '@web/components/utilities/svg/phosphorIcons/CheckBold.vue';

export default {
  components: { CheckBold },
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  computed: {
    requirements() {
      return [
        {
          label: `8+ characters`,
          value: this.password.length >= 8,
        },
        {
          label: 'Mix of uppercase & lowercase letters',
          value: /(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u.test(this.password),
        },
        {
          label: 'Includes a number',
          value: /\d/.test(this.password),
        },
        {
          label: 'Includes a symbol',
          value: /\p{Z}|\p{S}|\p{P}/u.test(this.password),
        },
      ];
    },
  },
};
</script>
