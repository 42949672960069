<template>
  <div class="w-full max-w-md">
    <form @submit.prevent="submitForm">
      <div class="relative mb-8">
        <h1
          class="text-left text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
        >
          Choose a new password
        </h1>
        <Alert v-if="error" type="error" class="mt-4">
          {{ errorMsg }}
        </Alert>
        <div
          class="mt-6 rounded bg-gray-200 p-6 dark:bg-gray-900 dark:text-white"
        >
          Passwords must be at least <strong>8 characters</strong> and contain
          all of the following:
          <ul
            class="mt-2 grid list-inside list-disc grid-cols-1 gap-1 sm:grid-cols-2"
          >
            <li>Uppercase letter</li>
            <li>Lowercase letter</li>
            <li>Number</li>
            <li>Symbol</li>
          </ul>
        </div>
      </div>
      <label for="password" class="mb-1 inline-block"> New Password </label>
      <input
        id="password"
        v-form-control="{
          invalid: v$.password.$error && !v$.password.$pending,
        }"
        v-model="password"
        type="password"
        name="password"
        autocomplete="new-password"
        minlength="8"
        class="text-lg md:text-base"
        :required="true"
        :autofocus="true"
      />
      <Errors
        :errors="v$.password.$errors"
        :ignore-validators="['required']"
        class="mt-2"
      />
      <p class="mt-10 md:mt-14">
        <button
          type="submit"
          class="btn btn-purple"
          :class="{ loading: submitting }"
          :disabled="submitting"
        >
          Update
        </button>
      </p>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import solidPassword from '@web/vuelidators/solidPassword.js';
import Errors from '@web/components/utilities/Errors.vue';
import Alert from '@web/components/utilities/Alert.vue';

export default {
  components: {
    Alert,
    Errors,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      password: { required, solidPassword },
      $autoDirty: true,
    };
  },
  data() {
    return {
      submitting: false,
      error: false,
      errorMsg: '',
      email: this.$route.query['email'],
      password: '',
    };
  },
  methods: {
    reset() {
      this.error = false;
      this.errorMsg = '';
    },
    async submitForm() {
      this.submitting = true;
      this.reset();
      const isValid = await this.v$.$validate();
      if (!isValid) {
        this.submitting = false;
        return;
      }
      axios
        .post('/ajax/password/reset', {
          token: this.$route.params.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
        })
        .then((response) => {
          window.location.replace('/');
        })
        .catch((err) => {
          this.submitting = false;
          this.error = true;
          this.errorMsg = err.getErrorMessage();
          if (!err.hasErrorMessage()) {
            throw err;
          }
        });
    },
  },
};
</script>
