<template>
  <div v-if="showNextSteps" class="w-full max-w-sm">
    <h1
      class="text-center text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
    >
      You're all set!
    </h1>
    <div class="mt-6 flex flex-col space-y-5">
      <a
        v-if="isIPhone"
        class="btn btn-purple flex items-center justify-center"
        href="https://apps.apple.com/us/app/clearstream/id835273539"
      >
        Download the app

        <svg
          class="ml-2 size-4"
          width="21"
          height="25"
          viewBox="0 0 21 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 18.3406C20.1747 20.7156 17.704 24.9208 15.1588 24.9667C13.4704 24.999 12.9276 23.974 10.9977 23.974C9.06885 23.974 8.4651 24.9354 6.8691 24.9979C4.1685 25.101 0 18.9281 0 13.5448C0 8.6 3.4734 6.14896 6.5079 6.10417C8.1354 6.075 9.6726 7.19271 10.6649 7.19271C11.6613 7.19271 13.5282 5.84896 15.4907 6.04583C16.3118 6.08021 18.6186 6.37396 20.0991 8.52187C16.1711 11.0656 16.7832 16.3854 21 18.3406ZM15.5169 0C12.5496 0.11875 10.1283 3.20729 10.4664 5.76146C13.209 5.97292 15.8403 2.92292 15.5169 0Z"
            fill="currentColor"
          ></path>
        </svg>
      </a>
      <a
        v-if="isAndroid"
        class="btn btn-purple flex items-center justify-center"
        href="https://play.google.com/store/apps/details?id=com.clearstream.android"
      >
        Download the app

        <svg
          class="ml-2 size-4"
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.570999 23.6638L11.102 13.163L14.814 16.8639L2.295 23.8048C1.819 24.0688 1.236 24.0648 0.763 23.7938L0.570999 23.6638ZM10.04 12.104L0 22.1149V2.09314L10.04 12.104ZM16.314 7.96706L21.219 10.686C21.701 10.954 22 11.456 22 12C22 12.544 21.701 13.046 21.219 13.314L16.18 16.1069L12.165 12.104L16.314 7.96706ZM0.459999 0.433159C0.549999 0.34616 0.651 0.270161 0.763 0.206162C1.236 -0.0648348 1.819 -0.0688345 2.295 0.195162L14.948 7.21006L11.102 11.045L0.459999 0.433159Z"
            fill="currentColor"
          ></path>
        </svg>
      </a>
      <a class="btn btn-purple btn-outline w-full" href="/">
        Check out my dashboard →
      </a>
    </div>
  </div>
  <form v-else class="w-full max-w-md" @submit.prevent="submitForm">
    <div class="relative mb-6">
      <h1
        class="text-center text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
      >
        Welcome to Clearstream
      </h1>
      <div class="mt-2 text-center">Create a password to get started</div>
      <Transition name="fade-fast">
        <Alert v-if="error" type="error" class="mt-5">
          {{ error }}
        </Alert>
      </Transition>
    </div>
    <label for="password" class="mb-1 inline-block"> Password </label>
    <input
      id="password"
      v-form-control
      v-model="password"
      type="password"
      name="password"
      autocomplete="new-password"
      :required="true"
      :autofocus="true"
    />
    <PasswordRequirements class="mt-4" :password="password" />
    <p class="mt-6">
      <button
        type="submit"
        class="btn btn-purple w-full"
        :class="{ loading: submitting }"
        :disabled="submitting"
      >
        Log in →
      </button>
    </p>
  </form>
</template>

<script>
import Alert from '@web/components/utilities/Alert.vue';
import PasswordRequirements from '@web/components/utilities/PasswordRequirements.vue';

export default {
  components: {
    PasswordRequirements,
    Alert,
  },
  data() {
    return {
      submitting: false,
      error: null,
      password: '',
      showNextSteps: false,
    };
  },
  computed: {
    isIPhone() {
      return navigator.userAgent.match(/iPhone/i);
    },
    isAndroid() {
      return navigator.userAgent.match(/Android/i);
    },
  },
  watch: {
    password() {
      this.error = null;
    },
  },
  methods: {
    async submitForm() {
      this.submitting = true;
      this.error = null;

      axios
        .post('/ajax/signup/confirm', {
          token: this.$route.params.token,
          password: this.password,
        })
        .then(() => {
          if (this.isIPhone || this.isAndroid) {
            this.showNextSteps = true;
          } else {
            window.location.replace('/');
          }
        })
        .catch((err) => {
          this.submitting = false;
          this.error = err.getErrorMessage();

          if (!err.hasErrorMessage()) {
            throw err;
          }
        });
    },
  },
};
</script>
